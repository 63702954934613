import {Injectable} from '@angular/core'
import {BehaviorSubject, map, Observable, tap} from 'rxjs'
import {
  IBorgoApplicationResult,
  IBorgoCase,
  IBorgoCollateral,
  IBorgoCollateralResult,
  IBorgoPerson,
  TBorgoApplicationPurpose,
  TBorgoApplicationStatus,
  TBorgoCaseStatus
} from '@sparbanken-syd/borgo-types'
import {IBorgoPersonResult, IBorgoSearchPartyResult} from '../application/typings/borgo-types'
import {RequestService} from './request.service'

export interface ICaseQuery {
  caseId?: string | null
  includeStaff?: boolean | null
  page?: number | null
  limit?: number | null
  caseStatus?: TBorgoCaseStatus | null
  applicationStatus?: TBorgoApplicationStatus | null
  /**
   * INCREASE, TAKE_OVER etc.
   */
  applicationPurpose?: TBorgoApplicationPurpose | null
}

export interface ICaseListItem extends IBorgoCase {
  /**
   * Can be set and can be only one of the possible.
   */
  applicationResults?: IBorgoApplicationResult[]
}


const SESSION_TOKEN_NAME = 'borgo-loan-admin-at'

export interface IBorgoService {
  cases$: Observable<IBorgoCase[]>
  getUserApplication: (caseId: string, applicationId: string) => Observable<IBorgoApplicationResult>
  getCases: (query: ICaseQuery) => Observable<IBorgoCase[]>
}

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class BorgoService extends RequestService implements IBorgoService {

  public currentPage: number = 1

  public cases$: Observable<IBorgoCase[]>

  private pCases$ = new BehaviorSubject<IBorgoCase[]>([])

  constructor() {
    super(SESSION_TOKEN_NAME)
    this.cases$ = this.pCases$.asObservable()
  }

  public getUserApplication(caseId: string, applicationId: string): Observable<IBorgoApplicationResult> {
    return this.getBorgo<IBorgoApplicationResult>(`/cases/${caseId}/${applicationId}`)
  }

  public getCases(query: ICaseQuery): Observable<IBorgoCase[]> {
    /**
     * Let us think about these...
     */
    query.includeStaff = true
    query.limit = 100 // Max is 100
    // Remove all null and undefined from the object, but not 0 or ''
    Object.keys(query).forEach((k) => query[k] ?? delete query[k])

    const params = new URLSearchParams(query as any)
    const path = `/cases?${params.toString()}`
    return this.getBorgo<IBorgoCase[]>(path).pipe(
      tap((cases: IBorgoCase[]) => {
        this.pCases$.next(cases)
      }))
  }

  public getLoans(partyId: string): Observable<IBorgoCase[]> {
    const path = `/loans?partyId=${partyId}`
    return this.getBorgo<IBorgoCase[]>(path)
  }

  public getUser(partyId: string): Observable<IBorgoPerson> {
    const path = `/users/${partyId}`
    return this.getBorgo<IBorgoPersonResult>(path)
      .pipe(map((res: IBorgoPersonResult) => res.person[0]))
  }

  /**
   * Find a user based on 12-digit personnummer
   */
  public findUser(personnummer: string): Observable<IBorgoSearchPartyResult> {
    const path = `/users/search`
    const data = {
      nationalId: personnummer
    }
    return this.putBorgo<IBorgoSearchPartyResult, { nationalId: string }>(path, data)

  }

  public getCollaterals(partyId: string): Observable<IBorgoCollateral[]> {
    return this.putBorgo<IBorgoCollateralResult, any>('/collaterals', {partyId})
      .pipe(map(res => res.collateralObject))
  }
}
